export const VALID_CHATBOT_PATHS = [
  "intro/course",
  "tutorials/course",
  "tutorials/code-questions/course",
  "tutorials/submission-code/course",
  "tutorials/code-submission/course",
  "tutorials/introduction/course",
  "practice/task-archive/task/code-submission/course",
  "practice/task-archive/task/course",
  "ethics/course",
  "milestones/pioneer-women/quiz",
];
