import React, { useState, useEffect, useRef } from "react";
import "./chatbot.css";

const ChatbotInterface = ({ onClose, messages, onUserRequest }) => {
  const [inputMessage, setInputMessage] = useState("");
  const [isEnlarged, setIsEnlarged] = useState(false);
  const chatMessagesRef = useRef(null);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onUserRequest(inputMessage);
      setInputMessage("");
    }
  };

  const toggleEnlarge = () => {
    setIsEnlarged((prevState) => !prevState);
  };

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className={`chatbot-interface-window ${isEnlarged ? "enlarged" : ""}`}>
      <div className="chatbot-header">
        <h4>AI Chatbot</h4>
        <div>
          <button className="enlarge-button" onClick={toggleEnlarge}>
            {isEnlarged ? "🔽" : "🔼"}
          </button>
          <button className="close-button" onClick={onClose}>
            X
          </button>
        </div>
      </div>
      <div className="chatbot-body" ref={chatMessagesRef}>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`chat-message ${
              message.type === "user" ? "chat-message-user" : "chat-message-bot"
            }`}
          >
            <div
              className={`chat-message-content ${
                message.type === "user"
                  ? "chat-message-user-content"
                  : "chat-message-bot-content"
              }`}
            >
              {message.text}
            </div>
          </div>
        ))}
      </div>
      <div className="chatbot-footer">
        <input
          type="text"
          className="chatbot-input"
          placeholder="Type your message..."
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyUp={handleKeyPress}
        />
        <button
          className="send-button"
          onClick={() => {
            onUserRequest(inputMessage);
            setInputMessage("");
          }}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatbotInterface;
