import React, { useState } from "react";
import ChatbotInterface from "./chatbot-interface";

const ToggleChatbotButton = () => {
  const [isChatbotVisible, setIsChatbotVisible] = useState(false);
  const [messages, setMessages] = useState([]);

  const toggleChatbot = () => {
    console.log("AI chatbot button clicked");
    setIsChatbotVisible((prevState) => !prevState);
  };

  const handleUserRequest = (inputMessage) => {
    if (!inputMessage.trim()) return;

    setMessages((prevMessages) => [
      ...prevMessages,
      { type: "user", text: inputMessage },
    ]);

    simulateApiReply(inputMessage);
  };

  const simulateApiReply = (userMessage) => {
    setTimeout(() => {
      addReply(`Received your message: "${userMessage}"`);
    }, 1000);
  };

  const addReply = (message) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { type: "bot", text: message },
    ]);
  };

  return (
    <React.Fragment>
      <button onClick={toggleChatbot} className="styled-toggle-button">
        {isChatbotVisible ? "Close AI Chatbot" : "Chat with AI"}
      </button>
      {isChatbotVisible && (
        <ChatbotInterface
          onClose={toggleChatbot}
          messages={messages}
          onUserRequest={handleUserRequest}
        />
      )}
    </React.Fragment>
  );
};

export default ToggleChatbotButton;
